.box {
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.header-text {
  margin-left: 0.9vw;
  margin-bottom: 0.365vh;
  font-size: 1.6vh;
  font-weight: bold;
  color: rgb(22, 23, 22);
}

@media screen and (max-width: 500px) {
  .box {
    justify-content: center;
  }
}
