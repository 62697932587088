.week-overview {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  margin-top: var(--space-big);
  justify-content: center;
  padding-bottom: 1rem;
}

.week-overview-statistic-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 72px;
  box-shadow: 0 2px 4px 0 var(--box-shadow);
  border-radius: 8px;
  background-color: var(--white);
  margin-top: var(--space-medium);
}

.weekoverview-element-title {
  width: 200px;
  text-align: end;
  margin-right: var(--space-small);
}

.weekoverview-divider {
  background-color: var(--divider-grey);
  height: 1px;
  width: 500px;
}

.weekoverview-divider-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: var(--space-medium);
}
