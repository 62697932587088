.right-indicators-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 500px) {
  .right-indicators-container {
    margin-bottom: var(--space-small);
  }
}

.statistic-headline {
  font-size: 16px;
  font-family: Roboto;
  line-height: 16pt;
  margin-bottom: 16px;
  color: var(--black-grey);
  font-weight: 500;
}
