.control-panel {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 790px;
  height: 610px;
  border-radius: 4px;
  /*padding-top: 48px;*/
  align-self: center;
  margin: 48px auto 0 auto;
  justify-content: flex-start;
}
.produktionsplanung {
  width: 540px;
  height: 38px;
  margin-top: 32px;
  margin-left: 125px;
  font-family: Roboto, serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161716;
}
