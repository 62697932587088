.date-element {
  width: 88px;
  height: 24px;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #161716;
  padding-top: 28px;
  padding-left: 24px;
}