.coreTaskBox {
  height: 11vh;
  width: 16vw;
  min-width: 250px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(194, 194, 194);
}

.innerBox {
  padding: 1.215vh max(10px, 0.9vw) 0px max(10px, 0.9vw);
  display: flex;
  align-items: flex-start;
}

.coreTaskDate {
  font-size: 1.2vh;
  font-weight: 600;
  margin-right: 1vw;
  padding: 0.36vh 1.4vh;
  border-radius: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: rgb(248, 248, 248);
  color: rgb(74, 74, 74);
}

.coreTaskDate-red {
  background-color: rgb(214, 84, 74);
  color: rgb(255, 255, 255);
}

.coreTaskDate-green {
  background-color: rgb(148, 187, 87);
  color: rgb(255, 255, 255);
}

.coreTaskTag {
  text-align: left;
  font-size: 1.2vh;
  font-weight: 600;
  margin-right: 1vw;
  /* padding-top: 0.75vh; */
  color: rgb(74, 74, 74);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.coreTaskTitle {
  text-align: left;
  font-size: 1.46vh;
  color: rgb(22, 23, 22);
  font-weight: 600;
  margin-top: 0.7vh;
  margin-left: max(10px, 0.9vw);
  overflow: hidden;
  max-lines: 2;
}

.coreTaskImage {
  width: 5vh;
  height: 5vh;
  padding-bottom: 0.5vh;
}

.coreTaskMetaData {
  height: 5vh;
  padding-left: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.coreTaskMetaDataTop {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.coreTaskAssignee {
  font-size: 1.46vh;
  font-weight: 600;
  margin-right: 1vw;
  color: rgb(74, 74, 74);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.36vh;
}
