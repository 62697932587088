.public-display {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  align-self: center;
  margin-top: var(--space-big);
  justify-content: center;
  padding-bottom: 1rem;
}

.grid-container {
  flex: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.iconClass {
  height: 32px;
  width: 32px;
  margin-right: var(--space-tiny);
}
