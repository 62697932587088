.delete-button {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    margin: 20px 16px 16px auto;
}
.delete-spinner {

}
.active {
    object-fit: contain;
    height: 24px;
    width: 24px;
    padding: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.inactive {
    fill: #C2C2C2;
    object-fit: contain;
    height: 24px;
    width: 24px;
    padding: 8px;
}
