.header {
  font-family: Roboto;
  box-shadow: 0 2px 4px 0 var(--box-shadow);
  background-color: var(--white);
}

.top-row {
  display: grid;
  grid-template-columns: 0.5fr auto 0.5fr;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  height: 64px;
}
.bottom-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  height: 64px;
  margin-top: -30px;
}

.live-view-indicator {
  background-color: var(--light-grey);
  border-radius: 3px;
  width: 180px;
  height: 35px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statistics-container {
  height: 132px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.statistic-bloc {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 40px;
}

.statistic-divider-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 40px;
}

.switch {
  display: flex;
  align-items: center;
}

.pd-kk-logo {
  margin-left: var(--space-big);
  width: 127px;
  height: 41px;
  object-fit: contain;
}

.container-index {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 20px;
}
.container-neutral {
  color: var(--black);
  background-color: var(--inactive-background);
}
.container-red {
  background-color: var(--red);
}
.container-green {
  background-color: var(--green);
}
.container-yellow {
  background-color: var(--yellow);
}

.divider-statistics {
  background-color: var(--divider-grey);
  height: 72px;
  width: 1px;
  margin: 0px 42px 0px 42px;
}

@media screen and (max-width: 1300px) {
  .statistics-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-bottom: var(--space-medium);
  }

  .divider-statistics {
    height: 1px;
    width: 100%;
    max-width: 450px;
  }

  .statistic-bloc {
    margin-bottom: var(--space-medium);
  }

  .statistic-divider-container {
    margin-bottom: var(--space-medium);
  }
}

@media screen and (max-width: 500px) {
  .top-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    justify-content: space-evenly;
  }

  .pd-kk-logo {
    margin-left: 0px;
    margin-top: var(--space-small);
  }

  .switch {
    margin-bottom: var(--space-medium);
  }

  .live-view-indicator {
    margin-right: 0px;
    margin-bottom: var(--space-medium);
  }
}
