.coreTaskListOuterBox {
  width: fit-content;
  margin: 2.7vh 10% 0 10%;
}

.coreTaskListHeader {
  margin-bottom: 0.365vh;
  font-size: 1.6vh;
  font-weight: bold;
  color: rgb(22, 23, 22);
  text-align: left;
}

.coreTaskListTasks {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.025vh;
  margin-bottom: 3.24vh;
  column-gap: 0.81vw;
  row-gap: 1.3vh;
}

@media screen and (max-width: 500px) {
  .coreTaskListOuterBox {
    width: 100%;
    margin: 2.7vh 0 0 0;
  }

  .coreTaskListTasks {
    justify-content: center;
  }

  .coreTaskListHeader {
    text-align: center;
  }
}
