.box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
  margin-right: var(--space-small);
  height: 32px;
  width: 32px;
}

.box-container-inactive {
  color: var(--light-grey);
  background-color: var(--inactive-background);
}

.box-container-black {
  color: var(--black);
  background-color: var(--inactive-background);
}

.box-container-green {
  color: var(--white);
  background-color: var(--green);
}

.box-container-red {
  color: var(--white);
  background-color: var(--red);
}

.box-container-yellow {
  color: var(--white);
  background-color: var(--yellow);
}
