.card {
  width: 300px;
  margin: 20px 20px 0 20px;
}

.cardActionArea:hover {
  background-color: rgb(148, 187, 87);
  color: white;
}

.buttonBlockHeader {
  background-color: 'transparent';
  padding: 28px 0 0 0;
}

.buttonBlock {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 20px 0;
}

.clickableListContent {
  margin: auto;
  width: fit-content;
  align-items: center;
  justify-content: center;
}
