.header-middle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  font-family: Roboto;
  box-shadow: 0 2px 4px 0 var(--box-shadow);
  background-color: var(--white);
  height: 64px;
}

.header-left-area {
  display: flex;
  justify-content: flex-start;
}

.header-center-area {
  display: flex;
  justify-content: center;
}

.header-right-area {
  display: flex;
  justify-content: flex-end;
}

.kk-logo {
  align-self: center;
  width: 127px;
  height: 41px;
}

.logoutButton {
  align-self: right;
  margin-right: var(--space-medium);
}
