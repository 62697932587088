.time {
  display: flex;
  color: var(--black);
  font-weight: bold;
  font-size: 28px;
  height: 24px;
  width: 100px;
  justify-content: center;
  align-items: center;
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
}

.date {
  display: flex;
  color: var(--black);
  /* font-weight: ; */
  font-size: 16px;
  height: 24px;
  width: 53px;
  justify-content: center;
  align-items: center;
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
}
