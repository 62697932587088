.box-root {
  height: 8.5vh;
  width: 16vw;
  min-width: 250px;
  border-radius: 10px;
  border-style: solid;
}

.box-grey {
  border-width: 1px;
  border-color: rgb(194, 194, 194);
}

.box-red {
  border-width: 0.135vw;
  border-color: rgb(214, 84, 74);
}

.innerBox {
  padding: 1.215vh 0px 0px 0.9vw;
  display: flex;
  align-items: center;
}

.text-header {
  flex: 1;
  text-align: left;
  font-size: 1.46vh;
  color: rgb(22, 23, 22);
  font-weight: 600;
  margin-left: 0.324vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* max-width: 9vw; */
  min-width: 130px;
  overflow: hidden;
}

.text-time {
  font-size: 1.17vh;
  color: rgb(74, 74, 74);
  font-weight: 400;
  margin-right: 1.5vw;
}

.text-temperature-box {
  display: flex;
  margin-top: 0.16vh;
  align-items: center;
}

.text-temperature-number {
  font-size: 2.6vh;
}

.text-temperature-unit {
  flex: 1;
  text-align: left;
  font-size: 2.6vh;
  font-weight: 200;
  padding-left: 0.16vw;
}

.temperature-range {
  background-color: rgb(248, 248, 248);
  color: rgb(74, 74, 74);
  font-size: 1.2vh;
  font-weight: 600;
  margin-right: 1vw;
  padding: 0.36vh 1.4vh;
  border-radius: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
