.picker {
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: center;
  align-items: center;
}
.weektext {
  width: 128px;
  height: 24px;
  margin: 8px 16px;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #161716;
}
