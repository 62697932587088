.print-div {
    margin-top: 40px;
    margin-left: 48px;
}

@media print {
  body {
    padding: 0px 32px 0px 32px;
    font-family: "Roboto", sans-serif;
  }
  td {
   padding: 1px;
   word-wrap: break-word;
   overflow-wrap: break-word;
  }
  h1 {
   padding: 0px;
   font-size: 20px;
  }
  table {
   font-size: 11px;
   table-layout: fixed;
   width: 100%;
   border-collapse: collapse;
  }
  .left-align {
    text-align: left;
  }
  .bold-text {
    font-weight: bold;
  }
    .print-div {
        margin-top: 0px !important;
        margin-left: 0px !important;
    }
    .print-button {
        display: none !important;
    }
}
