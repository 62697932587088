.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 72px;
  box-shadow: 0 2px 4px 0 var(--box-shadow);
  border-radius: 8px;
  background-color: var(--white);
  margin: 0px var(--space-for-grid) var(--space-medium) var(--space-for-grid);
  padding-right: var(--space-small);
}

.kitchenContainer {
  flex-direction: row;
  display: flex;
}

.route-number {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-grey);
  color: var(--black);
  font-size: small;
  font-weight: bold;
  height: 32px;
  width: 32px;
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
  border-radius: 20px;
}

.vertical-line {
  border-left: 1px solid rgba(22, 23, 22, 0.1);
  height: 32px;
  margin-right: var(--space-small);
}

.icon {
  height: 20;
}

@media screen and (max-width: 500px) {
  .container {
    width: 90%;
    height: 96px;
  }

  .kitchenContainer {
    height: 72px;
    width: 240px;
    flex-wrap: wrap;
    align-content: space-between;
  }

  .route-number {
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }

  .vertical-line {
    height: 70px;
  }
}
