.outer-box {
  width: fit-content;
  margin: 2.7vh 10% 0 10%;
}

.sensors {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.025vh;
  margin-bottom: 3.24vh;
  column-gap: 0.81vw;
  row-gap: 1.3vh;
}

@media screen and (max-width: 500px) {
  .outer-box {
    width: 100%;
    margin: 2.7vh 0 0 0;
  }

  .sensors {
    justify-content: center;
  }
}
