.publicDisplay {
  display: flex;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  background-color: rgba(200, 200, 200, 0.3);
}

.sensorpush {
  display: flex;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  background-color: white;
}

.coreTasks {
  display: flex;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  background-color: white;
}

.App-link {
  color: #09d3ac;
}

.controlPanel {
  display: flex;
  height: 100vh;
  text-align: center;
  flex-direction: column;
  background-color: rgba(200, 200, 200, 0.3);
}
