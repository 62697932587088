.container-date-element {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #161716;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 8px;
  text-align: left;
}
.status-bar {
  display: flex;
  height: 16px;
  flex-direction: row;
  padding-left: 24px;
  padding-bottom: 16px;
  text-align: left;
}
.status-bar-text {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #161716;
  padding-left: 4px;
  overflow: hidden;
}
.container-centered {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.centered-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161716;
}
