.button {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  margin: 20px 16px 16px auto;
}

.inactive {
  fill: #c2c2c2;
  object-fit: contain;
  height: 24px;
  width: 24px;
  padding: 8px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
