.loginContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  width: 420px;
  border-radius: 4px;
  margin: 148px auto 0 auto;
  padding: 0px 32px 32px 32px;
}

@media screen and (max-width: 500px) {
  .loginContent {
    width: 70%;
  }
}

.loginTitle {
  font-family: Roboto, serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161716;
}
