.container-delivery-day {
  display: flex;
  flex-direction: row;
  width: 730px;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  margin-left: 30px;
}

.divider {
  width: 1px;
  height: 48px;
  background-color: #c2c2c2;
  align-self: center;
  margin-left: 16px;
}
