body {
  margin: 0;
  background-color: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./fonts/Roboto-Regular.ttf') format('truetype');
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: black;
  --black-grey: rgb(22, 33, 22);
  --divider-grey: rgb(194, 194, 194);
  --light-grey: lightgrey;
  --white: #ffffff;
  --red: crimson;
  --yellow: goldenrod;
  --green: yellowgreen;
  --box-shadow: rgba(51, 51, 51, 0.15);
  --inactive-background: rgba(100, 100, 100, 0.1);
  --space-tiny: 4px;
  --space-smaller: 8px;
  --space-small: 10px;
  --space-medium: 16px;
  --space-for-grid: 20px;
  --space-big: 40px;
  /* --space-bigger: 64px; */
}
