.wb-active {
  fill: black;
  object-fit: contain;
  height: 20px;
  width: 20px;
  padding: 10px;
}

.wb-inactive {
  fill: #c2c2c2;
  object-fit: contain;
  height: 20px;
  width: 20px;
  padding: 10px;
}

.week-button {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  margin: 16px;
}
